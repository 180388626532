import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, type = 'user', affiliate = false, ...rest }) => {
  const isLogin = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY) ? true : false
  const currentUserData = JSON.parse(localStorage.getItem("currentUserData"))
  const isAffiliate = currentUserData && currentUserData.affiliates && currentUserData.affiliates.length > 0
  const userType = currentUserData && currentUserData.type
  return (
    <Route {...rest} render={props => (
      isLogin ?
        affiliate && !isAffiliate ?
          <Redirect to="/" /> :
          userType === type || type === 'both' ? <Component {...props} /> : <Redirect to="/" />
        : <Redirect to="/login-choose" />
    )} />
  )
}

export default PrivateRoute;